/* eslint-disable max-lines */
import AuthLayout from "../layout/auth/AuthLayout";
import PrivateLayout from "../layout/private/PrivateLayout";
// Announcement
// Auth Routes
import Login from "../pages/auth/Login";
// Private Routes
import Home from "../pages/Home";
// Lines
import Lines from "../pages/lines-managements/lines";
import AddLine from "../pages/lines-managements/lines/AddLine";
import ListOfLines from "../pages/lines-managements/lines/ListOfLines";
import Prices from "../pages/lines-managements/prices";
import Trips from "../pages/lines-managements/trips";
// Reservations
import Reservations from "../pages/reservations";
// Operators
import RouteCan from "../components/users/permissions/RouteCan";
import _404 from "../pages/404";

import RouteSuperAdmin from "../components/users/permissions/SuperAdminRoute";
import AddAnnouncement from "../pages/announcement/AddAnnouncement";
import AnnouncementList from "../pages/announcement/AnnouncementsList";
import Blogs from "../pages/blogs";
import AddBlog from "../pages/blogs/AddBlog";
import Features from "../pages/bundles/features";
import FeatureForm from "../pages/bundles/features/BundleFeatureForm";
import BundlesPage from "../pages/bundles/manage";
import BundleForm from "../pages/bundles/manage/BundleForm";
import BundlesOrder from "../pages/bundles/order";
import BundleOrderEdit from "../pages/bundles/order/BundleOrderEdit";
import BundlesOrderCreate from "../pages/bundles/order/BundlesOrderCreate";
import BundleReservationsPage from "../pages/bundles/reservations";
import BundleReservationForm from "../pages/bundles/reservations/BundleReservationForm";
import CreateTrip from "../pages/lines-managements/trips/CreateTrip";
// import Stations from "../pages/locations";
// import CreateLocations from "../pages/locations/create";
import ChangeOperator from "../pages/operators/ChangeOperator";
import Operators from "../pages/operators/create";
import CreateOperator from "../pages/operators/create/CreateOperators";
import PromoCodes from "../pages/promotion-codes";
import AddPromotionCode from "../pages/promotion-codes/AddPromotionCode";
import ReservationEdit from "../pages/reservations/ReservationEdit";
import TripNotices from "../pages/trip-notices";
import CreateTripNotice from "../pages/trip-notices/CreateNotices";
import Roles from "../pages/user-roles/roles";
import AddRole from "../pages/user-roles/roles/AddRole";
import Invitation from "../pages/users/Invitation";
import AccessControl from "../pages/users/access-control";
import UsersOverview from "../pages/users/users-overview";
import EditUser from "../pages/users/users-overview/EditUser";
import InviteUser from "../pages/users/users-overview/InviteUser";
import InvitedUsers from "../pages/users/users-overview/InvitedUsers";
import Users from "../pages/users/users-overview/Users";

import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";
import App from "../App";
import { OfficeSalesProvider } from "../context/OfficeSalesContext";
import OperatorsDashboard from "../pages/dashboards/operators";
import TripsSales from "../pages/dashboards/operators/TripsSales";
// import Locations from "../pages/locations/create";
import Stations from "../pages/locations/create";
import Locations from "../pages/locations/create/CreateStation";
import Office from "../pages/office";
import ChooseLine from "../pages/office/ChooseLine";
import CreateReservation from "../pages/office/createOfficeReservation/index";
import Reviews from "../pages/reviews";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/invitation/:guid" element={<Invitation />} />
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/trips-sales/:tripId" element={<TripsSales />} />
      <Route element={<PrivateLayout />}>
        <Route
          path="/"
          element={
            // <RouteSuperAdmin>
              <OperatorsDashboard />
            // </RouteSuperAdmin>
          }
        />
        <Route
          path="/dashboard-gjirafa"
          index
          element={
            <RouteSuperAdmin>
            <Home />
          </RouteSuperAdmin>
          }
        />
        {/* <Route
          path="/reservations"
          element={
            <RouteCan permissions="reservation.old.ticket.read">
              <OldReservations />
            </RouteCan>
          }
        /> */}
        <Route path="/trips">
          <Route
            index
            element={
              <RouteCan permissions="route.trip.read">
                <Trips />
              </RouteCan>
            }
          />
          <Route
            path="create"
            element={
              <RouteCan permissions="route.trip.create">
                <CreateTrip editMode={false} />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="route.trip.update">
                <CreateTrip editMode={true} />
              </RouteCan>
            }
          />
        </Route>
        <Route
          path="/prices"
          element={
            <RouteCan permissions={["pricings.pricing.read"]}>
              <Prices />
            </RouteCan>
          }
        />
        <Route path="/lines" element={<Lines />}>
          <Route
            index
            element={
              <RouteCan permissions="route.line.read">
                <ListOfLines />
              </RouteCan>
            }
          />
          <Route
            path="add"
            element={
              <RouteCan permissions="route.line.update">
                <AddLine />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="route.line.update">
                <AddLine editMode={true} />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/locations">
          <Route
            index
            element={
              <RouteCan permissions="locations.station.read">
                <Stations />
              </RouteCan>
            }
          />
          <Route
            path="create"
            element={
              <RouteCan permissions="locations.station.create">
                <Locations />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="locations.station.update">
                <Locations editMode={true}/>
              </RouteCan>
            }
          />
        </Route>
        <Route path="/announcements">
          <Route
            index
            element={
              <RouteCan permissions="route.announcement.read">
                <AnnouncementList />
              </RouteCan>
            }
          />
          <Route
            path="add"
            element={
              <RouteCan permissions="route.announcement.create">
                <AddAnnouncement />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="route.announcement.update">
                <AddAnnouncement editMode={true} />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/promotioncodes">
          <Route
            index
            element={
              <RouteCan permissions="promotioncodes.promotioncode.read">
                <PromoCodes />
              </RouteCan>
            }
          />
          <Route
            path="add"
            element={
              <RouteCan permissions="promotioncodes.promotioncode.create">
                <AddPromotionCode />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="promotioncodes.promotioncode.update">
                <AddPromotionCode editMode={true} />
              </RouteCan>
            }
          />
        </Route>
        <Route
          path="/users"
          element={
            <RouteCan
              permissions={[
                "userroles.userrole.read",
                "userroles.userrole.create",
                "userroles.userrole.update",
                "permissions.permission.read",
                "users.user.update",
                "users.user.read",
                "users.user.create"
              ]}
            >
              <Outlet />
            </RouteCan>
          }
        >
          <Route path="roles">
            <Route
              index
              element={
                <RouteCan permissions="userroles.userrole.read">
                  <Roles />
                </RouteCan>
              }
            />
            <Route
              path="add"
              element={
                <RouteCan permissions="userroles.userrole.create">
                  <AddRole mode="create" />
                </RouteCan>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RouteCan permissions="userroles.userrole.update">
                  <AddRole mode="edit" />
                </RouteCan>
              }
            />
          </Route>
          <Route
            path="access"
            element={
              <RouteCan permissions="permissions.permission.read">
                <AccessControl />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="users.user.update">
                <EditUser />
              </RouteCan>
            }
          />
          <Route path="overview" element={<UsersOverview />}>
            <Route
              index
              element={
                <RouteCan permissions="users.user.read">
                  <Users />
                </RouteCan>
              }
            />
            <Route
              path="invite"
              element={
                <RouteCan permissions="users.user.read">
                  <InvitedUsers />
                </RouteCan>
              }
            >
              <Route
                path="add"
                element={
                  <RouteCan permissions="users.user.create">
                    <InviteUser />
                  </RouteCan>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/operator/change"
          element={
            <RouteCan permissions="users.user.operator.update">
              <ChangeOperator />
            </RouteCan>
          }
        />
        {/* <Route
          path="/operator/link"
          element={
            <RouteSuperAdmin>
              <LinkEmail />
            </RouteSuperAdmin>
          }
        /> */}
        <Route path="/bundles">
          <Route path="manage">
            <Route
              index
              element={
                <RouteCan permissions="travelbundles.travelbundle.read">
                  <BundlesPage />
                </RouteCan>
              }
            />
            <Route
              path="create"
              element={
                <RouteCan permissions="travelbundles.travelbundle.create">
                  <BundleForm mode="create" />
                </RouteCan>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RouteCan permissions="travelbundles.travelbundle.update">
                  <BundleForm mode="edit" />
                </RouteCan>
              }
            />
          </Route>
          <Route path="reservations">
            <Route
              index
              element={
                <RouteCan permissions="travelbundles.travelbundle.reservation.read">
                  <BundleReservationsPage />
                </RouteCan>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RouteCan permissions="travelbundles.travelbundle.reservation.update">
                  <BundleReservationForm />
                </RouteCan>
              }
            />
          </Route>
          <Route path="features">
            <Route
              index
              element={
                <RouteCan permissions="travelbundles.travelbundle.include.read">
                  <Features />
                </RouteCan>
              }
            />
            <Route
              path="create"
              element={
                <RouteCan permissions="travelbundles.travelbundle.include.create">
                  <FeatureForm mode="create" />
                </RouteCan>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RouteCan permissions="travelbundles.travelbundle.include.update">
                  <FeatureForm mode="edit" />
                </RouteCan>
              }
            />
          </Route>
          <Route
            path="order"
            element={
              <RouteCan permissions="travelbundles.travelbundle.diplayorder.read">
                <BundlesOrder />
              </RouteCan>
            }
          >
            <Route
              path="create"
              element={
                <RouteCan permissions="travelbundles.travelbundle.diplayorder.create">
                  <BundlesOrderCreate />
                </RouteCan>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RouteCan permissions="travelbundles.travelbundle.diplayorder.update">
                  <BundleOrderEdit />
                </RouteCan>
              }
            />
          </Route>
        </Route>
        <Route path="/blogs">
          <Route
            index
            element={
              <RouteCan permissions="blogs.blog.read">
                <Blogs />
              </RouteCan>
            }
          />
          <Route
            path="add"
            element={
              <RouteCan permissions="blogs.blog.create">
                <AddBlog mode="create" />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="blogs.blog.update">
                <AddBlog mode="edit" />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/reviews">
          <Route
            index
            element={
              <RouteCan permissions="reviews.review.read">
                <Reviews />
              </RouteCan>
            }
          />
          {/* <Route
            path="details/:id"
            element={
              <RouteCan permissions="blogs.blog.update">
                <ReviewD mode="edit" />
              </RouteCan>
            }
          /> */}
        </Route>
        <Route path="ticket/reservations">
          <Route
            index
            element={
              <RouteCan permissions="reservation.old.ticket.read">
                <Reservations />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="reservation.old.ticket.update">
                <ReservationEdit />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/notices">
          <Route
            index
            element={
              <RouteCan permissions="tripnotices.tripnotice.read">
                <TripNotices />
              </RouteCan>
            }
          />
          <Route
            path="create"
            element={
              <RouteCan permissions="tripnotices.tripnotice.create">
                <CreateTripNotice mode="create" />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="tripnotices.tripnotice.update">
                <CreateTripNotice mode="edit" />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/operators">
          <Route
            index
            element={
              <RouteCan permissions="operators.operator.read">
                <Operators />
              </RouteCan>
            }
          />
          <Route
            path="create"
            element={
              <RouteCan permissions="operators.operator.create">
                <CreateOperator mode="create" />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="operators.operator.update">
                <CreateOperator mode="edit" />
              </RouteCan>
            }
          />
        </Route>
        <Route path="/office" element={<OfficeSalesProvider />}>
          <Route
            index
            element={
              <RouteCan permissions="reservation.ticket.read">
                <Office />
              </RouteCan>
            }
          />
          <Route
            path="chooseLine"
            element={
              <RouteCan permissions="reservation.ticket.create">
                <ChooseLine />
              </RouteCan>
            }
          />
          <Route
            path="create"
            element={
              <RouteCan permissions="reservation.ticket.create">
                <CreateReservation mode="create" />
              </RouteCan>
            }
          />
          <Route
            path="edit/:id"
            element={
              <RouteCan permissions="reservation.ticket.update">
                <CreateReservation mode="edit" />
              </RouteCan>
            }
          />
        </Route>
        R
      </Route>
      <Route path="*" element={<_404 />} />
    </Route>
  )
);

export default router;
